<template>
  <div class="seedDeclarationBox">
    <div class="mainBox">
      <div class="main_inside">
        <div class="sdb_title">
          南繁
          <span>检疫数据</span>
        </div>
        <div class="sdb_midBox">
          <p>
            现已累计
            <span>3157</span>件产地检疫,
            <span>886</span>家检疫单位，涉及
            <span>46</span>种作物，
            <span>68.71</span>亩产检面积，核定种子产量
            <span>14.325</span>万吨
          </p>
          <p>
            核定苗木产量
            <span>20.02</span>万吨
          </p>
          <p>主要育种作物包括：水稻、玉米、高粱、棉花等品类。</p>
        </div>
        <div class="sdb_btnBox">
          <div class="sdb_btn" @click="toOtherWeb">
            <span class="sdb_btn_logo">立刻查看详情</span>
          </div>
        </div>
      </div>
      <el-row class="sdb_bottom">
        <el-col :span="6">
          <el-col :span="8">
            <img src="~@/assets/images/plant/sbzn-icon@2x.png" alt />
          </el-col>
          <el-col :span="16">
            <p class="red">申报指南</p>
            <p class="p_small">快速查看检疫申报的申请须知，申请流程等信息</p>
          </el-col>
        </el-col>
        <el-col :span="6">
          <el-col :span="8">
            <img src="~@/assets/images/plant/jycx-icon@2x.png" alt />
          </el-col>
          <el-col :span="16">
            <p class="yellow">检疫查询</p>
            <p class="p_small">快速查询检疫的相关信息</p>
          </el-col>
        </el-col>
        <el-col :span="6">
          <el-col :span="8">
            <img src="~@/assets/images/plant/cjsq-icon@2x.png" alt />
          </el-col>
          <el-col :span="16">
            <p class="blue">产检申请</p>
            <p class="p_small">快速查看产地检疫的申报及申请操作等信息</p>
          </el-col>
        </el-col>
        <el-col :span="6">
          <el-col :span="8">
            <img src="~@/assets/images/plant/djsq-icon@2x.png" alt />
          </el-col>
          <el-col :span="16">
            <p class="green">调检申请</p>
            <p class="p_small">快速查看调运检疫的申报及申请操作等信息</p>
          </el-col>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    toOtherWeb() {
      window.open("https://ywglyh.moa.gov.cn/idp/authcenter/ActionAuthChain?entityId=HHGX");
    }
  }
};
</script>
<style lang="less">
.seedDeclarationBox {
  .mainBox {
    width: 1200px;
    height: 600px;
    margin: 0 auto;
    background: url("~@/assets/images/plant/zwjy背景图@2x.png") no-repeat;
    background-size: 100%;
    .main_inside {
      width: 50%;
      height: 500px;
      .sdb_title {
        width: 460px;
        margin: 0 auto;
        color: #19316f;
        font-size: 30px;
        line-height: 120px;
        letter-spacing: 6px;
      }
      .sdb_midBox {
        width: 460px;
        height: 230px;
        margin: 0 auto;
        line-height: 50px;
        font-size: 14px;
        color: #7a85a0;
        span {
          color: #265ae1;
          font-size: 20px;
        }
      }
      .sdb_btnBox {
        width: 460px;
        margin: 0 auto;
        .sdb_btn {
          width: 160px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          color: #fff;
          background-color: #265ae1;
          margin: 40px 0;
          cursor: pointer;
          border-radius: 5px;
          .sdb_btn_logo::after {
            content: "\2794";
            color: #fff;
            border-radius: 50%;
            margin-left: 10px;
          }
        }
      }
    }
    .sdb_bottom {
      width: 100%;
      height: 100px;
      .p_small {
        font-size: 14px;
        color: #7a85a0;
      }
      .red {
        color: #fd6666;
        line-height: 30px;
      }
      .yellow {
        color: #fca14f;
        line-height: 30px;
      }
      .blue {
        color: #448afc;
        line-height: 30px;
      }
      .green {
        color: #42a98a;
        line-height: 30px;
      }
    }
  }
}
</style>
